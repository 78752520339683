import { routes } from '@/router';
import { defineComponent } from 'vue';
import { isDev, isStaging } from '../../../common-ui';
export default defineComponent({
  data: function data() {
    return {
      isDev: isDev,
      isStaging: isStaging,
      routes: routes
    };
  },
  computed: {
    routeName: function routeName() {
      return this.$route.path;
    }
  }
});